// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_1264 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 950px;
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 350px;
  z-index: 1;
}

.backstretch {
  position: relative;

  position: fixed !important;
  height: 100vh !important;

  // for top of page gradient
  &:before {
    @include before(1113px, 100%);
    // background: url(/i/design/gradient-left.png) repeat-y top left;
    background: linear-gradient(to right, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
    top: 0;
    left: 0;
  }

  img {
    top: 0px !important;
    min-width: 100% !important;
    min-height: 100% !important;
    object-fit: cover !important;
  }
}